import React, { SyntheticEvent } from 'react';
import cx from 'classnames';
import jsonp from 'jsonp';
import * as s from './s.module.css';
import Button from '../Button';
import config from '../../config';

interface State {
    name: string,
    address: string,
    email: string,
    passport: string,
    reservedJusticeNode: string,
    loading: boolean,
    error: string,
    result: string,
}

class JusticeSubscribeForm extends React.Component<{}, State, Number> {
    timerSubmitId: number;
    constructor(props: any) {
        super(props);
        this.state = {
            name: '',
            address: '',
            email: '',
            passport: '',
            reservedJusticeNode: '',
            loading: false,
            error: '',
            result: '',
        };
    }

    onFormSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault();
        const {
            name,
            address,
            email,
            passport,
            reservedJusticeNode,
        } = this.state;
        try {
            this.setState({ loading: true, error: '' });
            // user
            const u = config.mailchimp.userId;
            // Audience ID for "Justice Nodes Registration"
            const id = config.mailchimp.campaigns.justicesId;

            const encodedEmail = encodeURIComponent(email);
            const encodedName = encodeURIComponent(name);
            const encodedAddress = encodeURIComponent(address);
            const encodedJusticesNodes = encodeURIComponent(reservedJusticeNode);
            const encodedPassport = passport ?  encodeURIComponent(passport) : null;

            const response: any = await new Promise((resolve, reject) => {
                jsonp(
                    `https://poker.us14.list-manage.com/subscribe/post-json?u=${u}&id=${id}&EMAIL=${encodedEmail}&NAME=${encodedName}&ADDRESS=${encodedAddress}&JUSTICES=${encodedJusticesNodes}${encodedPassport ? `&PASSPORT=${encodedPassport}` : ''}`,
                    {
                        param: 'c'
                    },
                    (err, data) => {
                        if (err) {
                            reject(err);
                        } else {
                            resolve(data);
                        }
                    }
                );
            })
            const initialState = {
                name: '',
                address: '',
                email: '',
                passport: '',
                reservedJusticeNode: '',
                loading: false,
                error: '',
                result: '',
            };
            if (response.result === "error") {
                if (response.msg.includes('is already subscribed to list')) {
                    this.setState({
                        loading: false,
                        error: `${this.state.email} is already subscribed...`,
                    });
                } else if (response.msg.includes("has too many recent signup requests") && response.msg.startsWith('Recipient')) {
                    this.setState({
                        ...initialState,
                        error: response.msg
                    });
                } else {
                    this.setState({
                        loading: false,
                        error: `We're sorry. An error occured...`,
                    });
                }
            } else {
                this.setState({
                    ...initialState,
                    result: response.msg,
                });
            }
            clearTimeout(this.timerSubmitId);
            this.timerSubmitId = window.setTimeout(() => this.setState({ result: '', error: '' }), 10000);
        } catch (error) {
            this.setState({ loading: false, error: `We're sorry. An error occured...` });
            clearTimeout(this.timerSubmitId);
            this.timerSubmitId = window.setTimeout(() => this.setState({ result: '', error: '' }), 10000);
        }
    }
    onChangeName = (e: SyntheticEvent<HTMLInputElement>) => {
        const target = e.currentTarget;
        this.setState({
            name: target.value,
        });
    }
    onChangeAddress = (e: SyntheticEvent<HTMLInputElement>) => {
        const target = e.currentTarget;
        this.setState({
            address: target.value,
        });
    }
    onChangeEmail = (e: SyntheticEvent<HTMLInputElement>) => {
        const target = e.currentTarget;
        this.setState({
            email: target.value,
        });
    }
    onChangePassport = (e: SyntheticEvent<HTMLInputElement>) => {
        const target = e.currentTarget;
        this.setState({
            passport: target.value,
        });
    }
    onChangereservedJusticeNode = (e: SyntheticEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        if (/^[1-9]+[0-9]*$/.test(value) || !value) {
            this.setState({
                reservedJusticeNode: value,
            });
        }
    }
    render() {
        const {
            name,
            address,
            email,
            passport,
            reservedJusticeNode,
            loading,
            error,
            result,
        } = this.state;
        const buttonDisabled = !name || !address || !email || !reservedJusticeNode;
        return (
            <form className={s.containerForm} onSubmit={this.onFormSubmit}>
                <div className={s.marginForm}>
                    <div className={s.infoContainer}>
                        <h3 className={s.gradientTextColor}>Registration</h3>
                        <p>In order to register and reserve your Justice(s) nodes you must complete the following:</p>
                    </div>
                    <div className={s.inputLine}>
                        <div className={cx(s.actionContainer, s.actionContainerPaddingLeft)}>
                            <p>Name</p>
                            <div className={s.inputWrapperBorderColor}>
                                <input placeholder={'Enter your name'} type="text" name="NAME" id="NAME" value={name} onChange={this.onChangeName} />
                            </div>
                        </div>
                        <div className={cx(s.actionContainer, s.actionContainerPaddingRight)}>
                            <p>Address</p>
                            <div className={s.inputWrapperBorderColor}>
                                <input placeholder={'Enter your address details'} type="text" name="ADDRESS" id="ADDRESS" value={address} onChange={this.onChangeAddress} />
                            </div>
                        </div>
                    </div>
                    <div className={s.inputLine}>
                        <div className={cx(s.actionContainer, s.actionContainerPaddingLeft)}>
                            <p>Email</p>
                            <div className={s.inputWrapperBorderColor}>

                                <input placeholder={'Enter your email address'} type="email" autoCapitalize="off" autoCorrect="off" name="EMAIL" id="EMAIL" value={email} onChange={this.onChangeEmail} />
                            </div>
                        </div>
                        <div className={cx(s.actionContainer, s.actionContainerPaddingRight)}>
                            <p>Enter the passport number or company address for the individual or company that purchased tokens</p>
                            <div className={s.inputWrapperBorderColor}>
                                <input placeholder={'Enter your valid passport id'} type="text" name="PASSPORT" id="PASSPORT" value={passport} onChange={this.onChangePassport} />
                            </div>
                        </div>
                    </div>
                    <div className={s.hr} />
                    <div className={cx(s.infoContainer, s.noPaddingBottom)}>
                        <h3 className={s.gradientTextColor}>Cost</h3>
                        <p>As a reminder, each justice node is <span className={s.bold}>200,000 VPP</span> tokens.</p>
                    </div>
                    <div className={s.hr} />
                    <div className={s.infoContainer}>
                        <h3 className={s.gradientTextColor}>Indicate Amount of Justice Nodes</h3>
                        <p>Please indicate the number of Justice Nodes you would like to reserve:</p>
                    </div>
                    <div className={cx(s.actionContainer, s.fullWidth)}>
                        <p>Number of Justice Nodes</p>
                        <div className={s.inputWrapperBorderColor}>
                            <input placeholder={'Enter number'} type="text" name="JUSTICES" id="JUSTICES" value={reservedJusticeNode} onChange={this.onChangereservedJusticeNode} />
                        </div>
                    </div>
                    <div className={cx(s.actionContainer, s.fullWidth)}>
                        <p>We will contact you when the VPP token is unlocked to submit your tokens to stake.</p>
                    </div>
                    <div className={s.hr} />
                    <div className={cx(s.infoContainer, s.noPaddingBottom)}>
                        <h3 className={s.gradientTextColor}>Lock Up</h3>
                        <p>
                            The number of tokens you submit will be locked for <span className={s.bold}>6 months from the network launch</span>.
                            </p>
                    </div>
                    <div className={s.hr} />
                    <div className={cx(s.infoContainer, s.noPaddingBottom)}>
                        <h3 className={s.gradientTextColor}>Justice Cap</h3>
                        <p>There will be a <span className={cx(s.bold, s.greenColor)}>400 Justice Cap for 12 months</span> after launch of the Justice Network.</p>
                    </div>
                    <div className={s.hr} />
                    <div className={cx(s.infoContainer, s.noPaddingBottom)}>
                        <h3 className={s.gradientTextColor}>What is a Justice</h3>
                        <p>
                            Justices are validator nodes on the Virtue Poker network, signing each transaction for every hand on the platform and
                            submitting hand histories for storage, but are never dealt cards. Justices are rotated automatically every few hands.
                                </p>
                        <p>
                            The Justice Pool is composed of a limited number of users that are active on the Virtue Poker network. To become a Justice,
                            users must acquire VPPs and stake tokens in the Justice Registry. You will have your Justice nodes assigned to tables on
                            your behalf for the initial launch of the Justice System.
                                </p>
                    </div>
                    <div className={s.hr} />
                    <div className={cx(s.infoContainer, s.noPaddingBottom)}>
                        <h3 className={s.gradientTextColor}>Core Functions of Justices</h3>
                        <p>Justices provide three core functions to the Virtue Poker network:</p>
                        <div className={s.listItem}>
                            <div className={s.circle} />
                            <p>Dispute resolution: In the rare instance two peers at a table disagree as to the state of the table at the end of a hand
                            or a game, a Justice resolves the dispute in real-time and awards the pot to the winner.
                                </p>
                        </div>
                        <div className={s.listItem}>
                            <div className={s.circle} />
                            <p>Data feed: Each Justice submits each action of every hand to a database so hand histories can be stored.</p>
                        </div>
                        <div className={s.listItem}>
                            <div className={s.circle} />
                            <p>Partial storage of player encryption keys: The "Dropped Player Problem" of Mental Poker occurs when a player drops out of
                            a hand prior to its completion. This is problematic, as all players must share encryption keys for community cards to be
                            revealed and for a hand to be completed. Using Shamir's Secret Sharing, each player's keys can be encripted and
                            split amongst all players plus the Justice. If the player drops out for any reason, the Justice can request the
                            pieces from each player and decrypt the assembled pieces so that the hand can be completed.
                                </p>
                        </div>
                    </div>
                    <div className={s.hr} />
                    <div className={s.infoContainer}>
                        <h3 className={s.gradientTextColor}>Fees to Justices</h3>
                        <p>Virtue Poker for the first 12 months of the Justice System will implement a <span className={s.bold}>400 Justice cap</span>.
                            </p>
                        <p>
                            Up to <span className={cx(s.bold, s.greenColor)}>50% of the rake</span> from Virtue Poker will go to the Justices during this time period.
                            </p>
                    </div>
                    <div className={s.tableScroll}>
                        <div className={s.tableContainer}>
                            <div className={s.tableColumn}>
                                <div className={cx(s.columnItem, s.columnHeader, s.cornerTopLeft)}>
                                    Year (Forecast)
                                    </div>
                                <div className={s.columnItem}>
                                    1
                                    </div>
                                <div className={s.columnItem}>
                                    2
                                    </div>
                                <div className={cx(s.columnItem, s.cornerBottomLeft)}>
                                    3
                                    </div>
                            </div>
                            <div className={s.tableColumn}>
                                <div className={cx(s.columnItem, s.columnHeader)}>
                                    Individual Justice Stake
                                    </div>
                                <div className={s.columnItem}>
                                    200.000 VPP
                                    </div>
                                <div className={s.columnItem}>
                                    200.000 VPP
                                    </div>
                                <div className={s.columnItem}>
                                    200.000 VPP
                                    </div>
                            </div>
                            <div className={s.tableColumn}>
                                <div className={cx(s.columnItem, s.columnHeader)}>
                                    Total Justice Stake
                                    </div>
                                <div className={s.columnItem}>
                                    80 Million
                                    </div>
                                <div className={s.columnItem}>
                                    80 Million
                                    </div>
                                <div className={s.columnItem}>
                                    80 Million
                                    </div>
                            </div>
                            <div className={s.tableColumn}>
                                <div className={cx(s.columnItem, s.columnHeader)}>
                                    Virtue Poker Annual Rake
                                    </div>
                                <div className={s.columnItem}>
                                    3000 ETH
                                    </div>
                                <div className={s.columnItem}>
                                    6000 ETH
                                    </div>
                                <div className={s.columnItem}>
                                    12000 ETH
                                    </div>
                            </div>
                            <div className={s.tableColumn}>
                                <div className={cx(s.columnItem, s.columnHeader)}>
                                    Virtue Poker Monthly Rake
                                    </div>
                                <div className={s.columnItem}>
                                    250 ETH
                                    </div>
                                <div className={s.columnItem}>
                                    500 ETH
                                    </div>
                                <div className={s.columnItem}>
                                    1000 ETH
                                    </div>
                            </div>
                            <div className={s.tableColumn}>
                                <div className={cx(s.columnItem, s.columnHeader)}>
                                    Virtue Poker Justice Fees (Rake/2)
                                    </div>
                                <div className={s.columnItem}>
                                    1500 ETH
                                    </div>
                                <div className={s.columnItem}>
                                    3000 ETH
                                    </div>
                                <div className={s.columnItem}>
                                    6000 ETH
                                    </div>
                            </div>
                            <div className={s.tableColumn}>
                                <div className={cx(s.columnItem, s.columnHeader, s.cornerTopRight)}>
                                    ETH Fees Per Justice Per Year
                                    </div>
                                <div className={s.columnItem}>
                                    3.75 ETH
                                    </div>
                                <div className={s.columnItem}>
                                    7.5 ETH
                                    </div>
                                <div className={cx(s.columnItem, s.cornerBottomRight)}>
                                    15 ETH
                                    </div>
                            </div>
                        </div>
                    </div>
                    <Button disabled={buttonDisabled} className={s.submitBtn} type="submit" name="submit" onClick={() => { }} loading={loading}>
                        Submit
                        </Button>
                    {error && <p className={s.errorText}>{error}</p>}
                    {result && <p className={s.resultText}>Done!</p>}
                </div>
            </form>
        )
    }
}

export default JusticeSubscribeForm;