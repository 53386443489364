import React from "react";
import Layout from "../layout/default";
import Seo from "../components/seo";
import JusticeNodesRegistration from '../components/JusticeNodesRegistration';

const JusticePage = ({ location, pageContext }) => (
    <Layout location={location} dynamicDefaultPaths={pageContext.dynamicDefaultPaths}>
        <Seo title="Justice Nodes Registration" />
        <JusticeNodesRegistration />
    </Layout>
)

export default JusticePage;
