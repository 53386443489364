// extracted by mini-css-extract-plugin
export var actionContainer = "s-module--actionContainer--44f94";
export var actionContainerPaddingLeft = "s-module--actionContainerPaddingLeft--d0504";
export var actionContainerPaddingRight = "s-module--actionContainerPaddingRight--c7ac6";
export var bold = "s-module--bold--979cb";
export var circle = "s-module--circle--d21b2";
export var columnHeader = "s-module--columnHeader--3cabb";
export var columnItem = "s-module--columnItem--b85c2";
export var containerForm = "s-module--containerForm--fd954";
export var cornerBottomLeft = "s-module--cornerBottomLeft--860d6";
export var cornerBottomRight = "s-module--cornerBottomRight--f7872";
export var cornerTopLeft = "s-module--cornerTopLeft--fb21e";
export var cornerTopRight = "s-module--cornerTopRight--1638f";
export var errorText = "s-module--errorText--32377";
export var etherscanLink = "s-module--etherscanLink--ee8f6";
export var fullWidth = "s-module--fullWidth--9ba56";
export var gradientTextColor = "s-module--gradientTextColor--f9c20";
export var greenColor = "s-module--greenColor--efe02";
export var hr = "s-module--hr--eb07c";
export var infoContainer = "s-module--infoContainer--e5863";
export var inputLine = "s-module--inputLine--3de0b";
export var inputWrapperBorderColor = "s-module--inputWrapperBorderColor--f9751";
export var listItem = "s-module--listItem--0a693";
export var marginForm = "s-module--marginForm--71015";
export var noPaddingBottom = "s-module--noPaddingBottom--4e28a";
export var resultText = "s-module--resultText--3b089";
export var submitBtn = "s-module--submitBtn--94386";
export var tableColumn = "s-module--tableColumn--8fef6";
export var tableContainer = "s-module--tableContainer--0eb2c";
export var tableScroll = "s-module--tableScroll--a682c";
export var textRevealAnimation = "s-module--textRevealAnimation--92543";
export var totalStakedBold = "s-module--totalStakedBold--264b4";