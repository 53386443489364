import React from 'react'
import JusticeNodesRegistrationForm from '../JusticeNodesRegistrationForm';
import background from '../../assets/images/vp-justice-bg.png';;
import logo from '../../assets/images/vp-logo.svg';
import * as s from './s.module.css';

class JusticeNodesRegistration extends React.Component {
    render() {
        return (
            <section className={s.sectionContainer}>
                <div className={s.sectionContent}>
                    <img className={s.logo} src={logo} />
                    <h3 className={s.title}>Welcome to the <span className={s.superbold}>First Step</span> of Staking and Becoming a Justice on the Virtue Poker Network!</h3>
                    <JusticeNodesRegistrationForm />
                </div>
                <img className={s.justiceBackground} src={background} />
            </section>

        )
    }
}

export default JusticeNodesRegistration;
